import { createSignal } from 'solid-js';
import { MOVE_IN_DATE } from '~/assets/strings';
import { DatePicker } from '~/components/ui';
import { useLocalization } from '~/hooks/useLocalization';
import { SearchBase } from './SearchBase';

interface SearchMoveInDateProps {
  moveInDate: string;
  onChange: (value: any) => void;
  valueDisplay?: string;
}

export const SearchMoveInDate = (props: SearchMoveInDateProps) => {
  const { t } = useLocalization();
  const [moveInDate, setMoveInDate] = createSignal<any>();

  const onApply = () => {
    props.onChange?.(moveInDate());
  };

  const onOutsideClick = () => {
    setMoveInDate(props.moveInDate);
  };

  return (
    <SearchBase title={props.valueDisplay || t(MOVE_IN_DATE)} onApply={onApply} onOutsideClick={onOutsideClick}>
      <div class="flex flex-col gap-[22px]  bg-white p-6 ">
        <DatePicker value={moveInDate()} onChange={setMoveInDate} />
      </div>
    </SearchBase>
  );
};
