import { useNavigate } from '@solidjs/router';
import { Show } from 'solid-js';
import IconBathroom from '~/assets/images/home/bathroom.svg';
import IconDefaultUnit from '~/assets/images/home/defaultUnit.svg';
import IconManager from '~/assets/images/home/demoUser.png';
import IconArea from '~/assets/images/home/ft.svg';
import IconLike from '~/assets/images/home/like.svg';
import IconRoom from '~/assets/images/home/room.svg';
import IconUnLike from '~/assets/images/home/unLike.svg';
import { FT, PINNED } from '~/assets/strings';
import useUseCase from '~/framework/hooks/useUseCase';
import { PresentableUnit } from '~/types/Unit';
import { SetLikedUseCase } from '~/use-cases/company-unit-listing/setLikedUseCase';
export interface PropertyItemInfo {
  id: string;
  image: string;
  title: string;
  description: string;
  price: string;
  amount: string;
  rooms: string;
  bathrooms: string;
  area: string;
  tags: string[];
  isLiked: boolean;
  isCollectioned: boolean;
  manager: string;
}

interface PropertyItemProps {
  data: PresentableUnit;
}

export const PropertyItem = (props: PropertyItemProps) => {
  const navigate = useNavigate();

  const { execute } = useUseCase(SetLikedUseCase);

  const handleLike = (unitId: string, isLiked: boolean, e: MouseEvent) => {
    e.stopPropagation();
    execute({
      unitId,
      isLiked,
    });
  };

  const renderTag = () => {
    return (
      <Show when={props.data.pinned}>
        <div class="flex h-[26px]  items-center justify-center rounded-[46px]  bg-danger px-[10px] py-1 text-xs text-white ">{PINNED}</div>
      </Show>
    );
  };
  return (
    <div
      style={{ 'box-shadow': '0px 12px 36px 0px #3B4D8114' }}
      class="w-full cursor-pointer overflow-hidden rounded-lg"
      onClick={() => navigate(`/company-website/property-detail/${props.data.id}`)}>
      <Show
        when={props.data.image}
        fallback={
          <div class="relative flex h-[240px] gap-[6px] bg-[#E7ECF6] bg-center bg-no-repeat p-5">
            <img src={IconDefaultUnit} alt="defaultUnit" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
            {renderTag()}
          </div>
        }>
        <div
          class="relative flex h-[240px] gap-[6px] bg-cover bg-center bg-no-repeat p-5"
          style={{ 'background-image': `url(${props.data.image})` }}>
          {renderTag()}
        </div>
      </Show>

      <div class="p-5">
        <div class="text-base font-medium text-[#363A3D]">{props.data.name}</div>
        <div class="mt-3 text-[24px]  font-semibold text-[#4A60A1]">{props.data.price}</div>
        <div class="mt-5 flex gap-6 text-base text-text-level03">
          <div class="flex items-center gap-1">
            <img src={IconRoom} alt="" /> {props.data.bedrooms}
          </div>
          <div class="flex items-center gap-1">
            <img src={IconBathroom} alt="" /> {props.data.bathrooms}
          </div>
          <div class="flex items-center gap-1">
            <img src={IconArea} alt="" /> {props.data.area} {FT}
          </div>
        </div>

        <div class="my-5 h-px w-full bg-[#E7E9EB]" />

        <div class="flex justify-between">
          <div class="flex items-center gap-1">
            <img class="size-[32px] rounded-full" src={IconManager} alt="" />
            <div class="text-sm font-medium text-[#363A3D]" />
          </div>

          <div class="flex items-center gap-3">
            {/* <img class="cursor-pointer" src={IconShare} alt="" /> */}
            <img
              class="w-[22px] cursor-pointer"
              onClick={(e) => handleLike(props.data.id, props.data.isLiked, e)}
              src={props.data.isLiked ? IconLike : IconUnLike}
              alt=""
            />
            {/* <img class="cursor-pointer" src={props.data.isCollectioned ? IconCollectioned : IconColl} alt="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
