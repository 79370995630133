import { Presenter } from '~/framework/Presenter';
import { PresentUnitParams } from '~/types/Unit';
import { User } from '~/use-cases/mainAppState';

export class CompanyUnitSearchParamsPresenter extends Presenter<PresentUnitParams> {
  protected createModel(state: User): PresentUnitParams {
    const searchParams = {
      AvailableFrom: state.unitParams?.AvailableFrom,
      MaxRentalPrice: state.unitParams?.MaxRentalPrice,
      MinBaths: state.unitParams?.MinBaths,
      MinBeds: state.unitParams?.MinBeds,
      MinRentalPrice: state.unitParams?.MinRentalPrice,
      Page: state.unitParams?.Page,
      PageSize: state.unitParams?.PageSize,
      Pinned: state.unitParams?.Pinned,
      PropertyType: state.unitParams?.PropertyType,
      Search: state.unitParams?.Search,
    };
    return {
      searchParams,
    };
  }
}
