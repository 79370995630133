import { createSignal, createEffect } from 'solid-js';
import { Select } from '~/components/ui';
import { SearchItemProps } from '~/types/SearchListing';
import { SearchBase } from './SearchBase';

export const SearchSelect = (props: SearchItemProps) => {
  const [data, setData] = createSignal<string>(props?.value || '');
  createEffect(() => {
    setData(props.value!);
  });

  const onChange = (value: string) => {
    setData(value);
  };

  const onApply = () => {
    props.onChange?.(data());
  };

  const onOutsideClick = () => {
    setData(props.value!);
  };

  return (
    <SearchBase title={props.valueDisplay || props.title} onApply={onApply} onOutsideClick={onOutsideClick}>
      <div class="flex flex-col gap-[22px]  bg-white p-6 ">
        <div class="rounded-lg border">
          <Select
            placeholder={props.placeholder}
            onChange={onChange}
            value={props.value}
            class="border-none"
            options={props.options || []}
          />
        </div>
      </div>
    </SearchBase>
  );
};
