import { Show, createSignal, createEffect } from 'solid-js';
import { Select } from '~/components/ui';
import { SearchRangeProps } from '~/types/SearchListing';
import { SearchBase } from './SearchBase';

interface SearchValue {
  start?: string;
  end?: string;
}

export const SearchRange = (props: SearchRangeProps) => {
  const [data, setData] = createSignal<SearchValue>(props?.value || {});

  createEffect(() => {
    setData(props.value!);
  });

  const onSelect = (value: string, type: 'start' | 'end') => {
    const newData = {
      ...data(),
      [type]: value,
    };
    setData(newData);
  };

  const onApply = () => {
    props.onChange?.(data());
  };

  const onOutsideClick = () => {
    setData(props.value!);
  };

  return (
    <SearchBase title={props.valueDisplay || props.title} onApply={onApply} onOutsideClick={onOutsideClick}>
      <div>
        <Show when={props.title}>
          <div class="h-9 bg-[#F5F5F9] px-6 text-xs leading-9 text-[#6B738A] ">{props.title}</div>
        </Show>
        <div class="flex items-center justify-between bg-white p-6">
          <Show when={props.start}>
            <div class="">
              <div class="mb-2 text-xs text-[#6B738A]">{props.start?.title}</div>
              <Select
                onChange={(e) => onSelect(e, 'start')}
                class="w-[100px] bg-[#F3F3F5]"
                placeholder={props.start?.placeholder}
                options={props.start?.options || []}
                value={data().start}
              />
            </div>
          </Show>
          <Show when={props.start && props.end}>
            <div class="mt-6 text-base text-[#6B738A]">-</div>
          </Show>
          <Show when={props.end}>
            <div class="">
              <div class="mb-2 text-xs text-[#6B738A]">{props.end?.title}</div>
              <Select
                onChange={(e) => onSelect(e, 'end')}
                class="w-[100px] bg-[#F3F3F5]"
                placeholder={props.end?.placeholder}
                options={props.end?.options || []}
                value={data().end}
              />
            </div>
          </Show>
        </div>
      </div>
    </SearchBase>
  );
};
