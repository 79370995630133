import { Show, For, createSignal, createEffect } from 'solid-js';
import { SearchItemProps } from '~/types/SearchListing';
import { cn } from '~/utils/classnames';

export const SearchRadio = (props: SearchItemProps) => {
  const [data, setData] = createSignal<string | number>(props.value!);

  createEffect(() => {
    setData(props.value!);
  });

  const handleChange = (value: string | number) => {
    setData(value);
    props.onChange?.(value);
  };

  return (
    <div>
      <Show when={props.title}>
        <div class="h-9 bg-[#F5F5F9] px-6 text-xs leading-9 text-[#6B738A] ">{props.title}</div>
      </Show>
      <div class="flex flex-col gap-[22px]  bg-white p-6 ">
        <div>{props.title}</div>
        <div class="flex w-fit border border-[#BCC0CE]">
          <Show when={props.options?.length}>
            <For each={props.options}>
              {(item, index) => {
                return (
                  <div
                    onClick={() => {
                      handleChange(item.value);
                    }}
                    class={cn(
                      ' cursor-pointer border-r border-[#BCC0CE] px-4 py-3',
                      index() === props.options?.length - 1 && 'border-r-0',
                      item.value === data() && 'border border-primary-color bg-[#0C48BC0F]'
                    )}>
                    {item.label}
                  </div>
                );
              }}
            </For>
          </Show>
        </div>
      </div>
    </div>
  );
};
