import { createSignal } from 'solid-js';
import { BATHROOMS, BEDROOMS, ANY, BEDS_AND_BATHS } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
import { SearchBase } from './SearchBase';
import { SearchRadio } from './SearchRadio';

interface SearchBedBathsProps {
  beds: number;
  baths: number;
  onChange: (value: any) => void;
  valueDisplay?: string;
}

export const SearchBedBaths = (props: SearchBedBathsProps) => {
  const { t } = useLocalization();
  const [beds, setBeds] = createSignal<any>();
  const [baths, setBaths] = createSignal<any>();

  const options = [
    { label: t(ANY), value: '' },
    { label: '1+', value: 1 },
    { label: '2+', value: 2 },
    { label: '3+', value: 3 },
    { label: '4+', value: 4 },
    { label: '5+', value: 5 },
  ];

  const onApply = () => {
    props.onChange?.({
      MinBaths: baths(),
      MinBeds: beds(),
    });
  };

  const onOutsideClick = () => {
    setBeds(props.beds);
    setBaths(props.baths);
  };

  return (
    <SearchBase title={props.valueDisplay || t(BEDS_AND_BATHS)} onApply={onApply} onOutsideClick={onOutsideClick}>
      <SearchRadio title={t(BEDROOMS)} options={options} value={beds} onChange={setBeds} />
      <SearchRadio title={t(BATHROOMS)} options={options} value={baths} onChange={setBaths} />
    </SearchBase>
  );
};
