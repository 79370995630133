import { APPLY } from '~/assets/strings';
import { Popover, IconChevronDown } from '~/components/ui';
import { useLocalization } from '~/hooks/useLocalization';
import { SearchBaseProps } from '~/types/SearchListing';

export const SearchBase = (props: SearchBaseProps) => {
  const { t } = useLocalization();
  const handleApply = () => {
    props.onApply();
  };

  const onOutsideClick = (e: MouseEvent) => {
    if ((e.currentTarget as Node).contains(e.relatedTarget as Node)) {
      e.preventDefault();
    }
    props.onOutsideClick();
  };

  return (
    <div class="">
      <Popover onOutsideClick={onOutsideClick}>
        <Popover.Trigger>
          <div class="flex items-center gap-[54px] rounded-lg border border-[#CBD4EB] px-4 py-[14px] text-[#222735]">
            {props.title}
            <IconChevronDown class="pointer-events-none size-5 cursor-pointer text-[#CBD4EB] transition-transform group-aria-expanded:rotate-180" />
          </div>
        </Popover.Trigger>
        <Popover.Content>
          <div class={`z-[99999] shadow-[0px_12px_16px_0px_#00000029] ${props.contentWrapClass}`}>
            {props.children}

            <div class="w-full ">
              <Popover.Trigger as="div" onClick={handleApply}>
                <div class="flex h-[44px] w-full cursor-pointer items-center justify-center bg-primary-color text-white">{t(APPLY)}</div>
              </Popover.Trigger>
            </div>
          </div>
        </Popover.Content>
      </Popover>
    </div>
  );
};
